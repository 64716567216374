<template>
  <v-container fluid v-if="!loading">
    <v-card outlined>
      <v-tabs v-model="tab" grow>
        <!-- Atrasados -->
        <v-tab>
          Atrasados
          <v-sheet class="ml-2 text-center px-2" rounded color="grey">
            <span class="white--text body-2 font-weight-bold ">
              {{ atrasados.length }}
            </span>
          </v-sheet>
        </v-tab>
        <v-divider vertical></v-divider>

        <!-- Pendentes -->
        <v-tab>
          Pendentes
          <v-sheet class="ml-2 text-center px-2" rounded color="grey">
            <span class="white--text body-2 font-weight-bold ">
              {{ pendentes.length }}
            </span>
          </v-sheet>
        </v-tab>
        <v-divider vertical></v-divider>

        <!-- Agendados -->
        <v-tab>
          Agendados
          <v-sheet class="ml-2 text-center px-2" rounded color="grey">
            <span class="white--text body-2 font-weight-bold ">
              {{ total(agendados) }}
            </span>
          </v-sheet>
        </v-tab>
        <v-divider vertical></v-divider>

        <!-- Futuros -->
        <v-tab>
          Futuros
          <v-sheet class="ml-2 text-center px-2" rounded color="grey">
            <span class="white--text body-2 font-weight-bold ">
              {{ futuros.length }}
            </span>
          </v-sheet>
        </v-tab>
        <v-divider vertical></v-divider>

        <!-- Aguardando Aprovação -->
        <v-tab>
          Aguardando Aprovação
          <v-sheet class="ml-2 text-center px-2" rounded color="grey">
            <span class="white--text body-2 font-weight-bold ">
              {{ concluidos.length }}
            </span>
          </v-sheet>
        </v-tab>
        <v-divider vertical></v-divider>
      </v-tabs>
    </v-card>

    <v-tabs-items v-model="tab" class="mt-5">
      <!-- Painel de Atrasados -->
      <v-tab-item>
        <v-card outlined>
          <v-card-title class="lightgray body-1">
            Eventos Atrasados
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <template v-if="atrasados.length">
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                  v-for="(evento, index) in atrasados"
                  :key="index"
                  class="pa-2"
                >
                  <MiniCardEvento :evento="evento" />
                </v-col>
              </v-row>
            </template>
            <template v-else>
              Nenhum evento atrasado.
            </template>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- Painel de Pendentes -->
      <v-tab-item>
        <v-card outlined>
          <v-card-title class="body-1 lightgray">
            Eventos Pendentes
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <template v-if="pendentes.length">
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                  v-for="(evento, index) in pendentes"
                  :key="index"
                  class="pa-2"
                >
                  <MiniCardEvento :evento="evento" />
                </v-col>
              </v-row>
            </template>
            <template v-else>
              Nenhum evento pendente.
            </template>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- Painel de Agendados -->
      <v-tab-item>
        <v-card outlined>
          <v-card-title class="body-1  pa-3">
            Eventos Agendados
            <v-spacer></v-spacer>
            <v-btn-toggle v-model="full_resume_mode" class="mr-3">
              <v-btn value="full" small>
                Detalhado
              </v-btn>

              <v-btn value="resume" small>
                Resumo
              </v-btn>
            </v-btn-toggle>
            <v-btn-toggle v-model="column_row_mode">
              <v-btn value="column" small>
                <v-icon color="grey darken-1" size="24">view_week</v-icon>
              </v-btn>

              <v-btn value="row" small>
                <v-icon color="grey darken-1" size="21">view_agenda</v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-card-title>
          <v-divider v-show="column_row_mode === 'column'"></v-divider>
          <v-card-text class="pa-0">
            <template v-if="agendados.length">
              <v-row class="px-3">
                <v-col
                  cols="12"
                  :md="column_row_mode === 'column' ? '3' : '12'"
                  :class="{
                    'pa-3': column_row_mode === 'column' ? true : false,
                    'pa-0': column_row_mode === 'row' ? true : false,
                  }"
                  v-for="(dia, index) in agendados"
                  :key="index"
                >
                  <v-divider v-show="column_row_mode === 'row'"></v-divider>
                  <template v-if="dia.eventos.length">
                    <v-card
                      :flat="column_row_mode === 'column' ? false : true"
                      :tile="column_row_mode === 'column' ? false : true"
                      :outlined="column_row_mode === 'column' ? true : false"
                    >
                      <v-expansion-panels flat :value="0">
                        <v-expansion-panel>
                          <v-expansion-panel-header
                            color="lightgray"
                            class="body-1 py-2"
                            style="min-height: 42px; max-height: 42px;"
                          >
                            <div style="flex: 0 1 auto; line-height: 1;">
                              {{ dia.data | moment("DD/MM/YYYY") }}
                            </div>

                            <v-sheet
                              class="ml-4 text-center"
                              color="grey"
                              max-width="30px"
                              rounded
                            >
                              <span
                                class="white--text body-2 font-weight-bold "
                              >
                                {{ dia.eventos.length }}
                              </span>
                            </v-sheet>
                          </v-expansion-panel-header>

                          <v-expansion-panel-content>
                            <v-divider></v-divider>
                            <v-card flat>
                              <v-card-text class="pa-4 pb-2">
                                <v-row>
                                  <v-col
                                    cols="12"
                                    :md="
                                      column_row_mode === 'column' ? '12' : '3'
                                    "
                                    v-for="(evento, index) in dia.eventos"
                                    :key="index"
                                    class="pa-2"
                                  >
                                    <MiniCardResumido
                                      v-show="full_resume_mode === 'resume'"
                                      :evento="evento"
                                    />
                                    <MiniCardEvento
                                      v-show="full_resume_mode === 'full'"
                                      :evento="evento"
                                    />
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-card>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-card>
                  </template>
                  <template v-else>
                    <v-card
                      :flat="column_row_mode === 'column' ? false : true"
                      :tile="column_row_mode === 'column' ? false : true"
                      :outlined="column_row_mode === 'column' ? true : false"
                    >
                      <v-expansion-panels :value="0" flat>
                        <v-expansion-panel>
                          <v-expansion-panel-header
                            color="lightgray"
                            class="body-1 py-2"
                            style="min-height: 42px; max-height: 42px;"
                          >
                            <div style="flex: 0 1 auto; line-height: 1;">
                              {{ dia.data | moment("DD/MM/YYYY") }}
                            </div>

                            <v-sheet
                              class="ml-4 text-center"
                              color="grey"
                              max-width="30px"
                            >
                              <span
                                class="white--text body-2 font-weight-bold "
                              >
                                {{ dia.eventos.length }}
                              </span>
                            </v-sheet>
                          </v-expansion-panel-header>

                          <v-expansion-panel-content>
                            <v-divider></v-divider>
                            <div class="pa-4 body-2">
                              Nenhum item agendado
                            </div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-card>
                  </template>
                </v-col>
              </v-row>
            </template>
            <template v-else>
              <v-card flat cols="12">
                Nenhum evento agendado.
              </v-card>
            </template>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- Painel Agendados Futuros -->
      <v-tab-item>
        <v-card outlined>
          <v-card-title class="body-1 lightgray">
            Eventos Futuros
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <template v-if="futuros.length">
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                  v-for="(evento, index) in futuros"
                  :key="index"
                  class="pa-2"
                >
                  <MiniCardEvento :evento="evento" />
                </v-col>
              </v-row>
            </template>
            <template v-else>
              Nenhum evento futuro.
            </template>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- Painel de Aguardando Aprovação -->
      <v-tab-item>
        <v-card outlined>
          <v-card-title class="body-1 lightgray">
            Aguardando Aprovação
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <template v-if="concluidos.length">
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                  v-for="(evento, index) in concluidos"
                  :key="index"
                  class="pa-2"
                >
                  <MiniCardEvento :evento="evento" />
                </v-col>
              </v-row>
            </template>
            <template v-else>
              Nenhum evento concluido.
            </template>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <!-- Dialog para abertura do evento -->
    <v-dialog
      @keydown.esc="voltarEventos"
      v-model="dialog"
      @click:outside="voltarEventos"
      width="100%"
      max-width="900px"
      scrollable
      :fullscreen="$vuetify.breakpoint.mdAndDown"
    >
      <router-view></router-view>
    </v-dialog>
  </v-container>
  <Carregando v-else />
</template>

<script>
import EventosPainelApi from "@/services/api/eventos-painel.api.js";
import MiniCardEvento from "@/components/paineis/MiniCardEvento.vue";
import MiniCardResumido from "@/components/paineis/MiniCardResumido.vue";

export default {
  name: "Painel",
  components: {
    MiniCardResumido,
    MiniCardEvento,
  },
  data: () => ({
    painelAtrasados: 0,
    painelPendentes: 0,
    painelAgendados: 0,
    painelFuturos: 0,
    painelConcluidos: 0,
    atrasados: [],
    pendentes: [],
    concluidos: [],
    agendados: [],
    futuros: [],
    loading: true,
    dialog: false,
    interval: "",
    tab: 2,
    column_row_mode: "row",
    full_resume_mode: "resume",
  }),
  computed: {},
  watch: {
    $route: {
      immediate: true,
      handler: function(newVal, oldVal) {
        this.dialog = newVal.meta && newVal.meta.dialog;
      },
    },
  },
  methods: {
    returnDayofWeek(data) {
      return this.$moment(data).weekday();
    },
    total(type) {
      let total = 0;
      if (type)
        type.forEach((item) => {
          total += item.eventos.length;
        });
      return total;
    },
    excerpt(text) {
      if (!text) return;
      if (text.length > 25) {
        return text.substr(0, 25) + "...";
      } else {
        return text;
      }
    },
    voltarEventos() {
      this.$router.push({
        name: "PainelEventos",
      });
    },
    getEventosPainel() {
      return EventosPainelApi.getEventosPainel()
        .then((response) => {
          this.atrasados = response.atrasados;
          this.pendentes = response.pendentes;
          this.agendados = response.agendados;
          this.concluidos = response.concluidos;
          this.futuros = response.agendados_futuro.eventos;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    reloadEventos() {
      const self = this;
      this.interval = setInterval(() => {
        self.getEventosPainel();
        console.log("eventos atualizados!");
      }, 60000);
    },
  },
  async mounted() {
    this.loading = true;
    await this.getEventosPainel();
    this.reloadEventos();
    this.loading = false;
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-expansion-panel-content {
  .v-expansion-panel-content__wrap {
    padding: 0px;
  }
}

// ::v-deep .theme--light .v-expansion-panel:before {
//   box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.12) !important;
// }

// ::v-deep .theme--dark .v-expansion-panel:before {
//   box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.13) !important;
// }
</style>
