var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-3",staticStyle:{"overflow":"hidden"},style:(hover ? ("border-color: " + (_vm.evento.cor) + " !important;") : ''),attrs:{"outlined":""},on:{"click":function($event){$event.stopPropagation();return _vm.goToEvento(_vm.evento.id)}}},[_c('h3',{staticClass:"mb-1",staticStyle:{"font-size":"14px","font-weight":"500"}},[_vm._v(" "+_vm._s(_vm.excerpt(_vm.evento.cliente, 32).toUpperCase())+" ")]),_c('div',{staticClass:"align-center justify-space-between",staticStyle:{"display":"flex"}},[_c('div',[_c('v-icon',{attrs:{"size":"30","color":_vm.evento.cor}},[_vm._v(_vm._s(_vm.evento.icone))])],1),_c('div',{staticClass:"align-center justify-space-between",staticStyle:{"display":"flex"}},[_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-sheet',_vm._g(_vm._b({staticClass:"mr-2 pa-1",attrs:{"color":"lightgray","rounded":""}},'v-sheet',attrs,false),on),[_c('v-icon',{attrs:{"size":"20","color":"grey darken-2"}},[_vm._v(" horizontal_split ")])],1)]}}],null,true)},[_c('v-card',{staticClass:"pa-2"},[_vm._v(" "+_vm._s(_vm.evento.protocolo)+" ")])],1),_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-sheet',_vm._g(_vm._b({staticClass:"mr-2 pa-1",attrs:{"color":"lightgray","rounded":""}},'v-sheet',attrs,false),on),[_c('v-icon',{attrs:{"size":"20","color":"grey darken-2"}},[_vm._v(" gavel ")])],1)]}}],null,true)},[_c('v-card',{staticClass:"pa-2"},[_vm._v(" "+_vm._s(_vm.evento.eventotipo)+" ")])],1),_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-sheet',_vm._g(_vm._b({staticClass:"mr-2 pa-1",attrs:{"color":"lightgray","rounded":""}},'v-sheet',attrs,false),on),[_c('v-icon',{attrs:{"size":"20","color":"grey darken-2"}},[_vm._v(" event ")])],1)]}}],null,true)},[_c('v-card',{staticClass:"pa-2"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.evento.agendado_data,"DD/MM/YYYY"))+" "+_vm._s(_vm.evento.agendado_hora ? "as" : "")+" "+_vm._s(_vm.evento.agendado_hora)+" ")])],1),_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-sheet',_vm._g(_vm._b({staticClass:"mr-2 pa-1",attrs:{"color":"lightgray","rounded":""}},'v-sheet',attrs,false),on),[_c('v-icon',{attrs:{"size":"20","color":"grey darken-2"}},[_vm._v(" room ")])],1)]}}],null,true)},[_c('v-card',{staticClass:"pa-2"},[_vm._v(" "+_vm._s(_vm.evento.cidade)+" ")])],1),_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.tags && _vm.tags.length)?_c('v-sheet',_vm._g(_vm._b({staticClass:"mr-2 pa-1",attrs:{"color":"lightgray","rounded":""}},'v-sheet',attrs,false),on),[_c('v-icon',{attrs:{"size":"20","color":"grey darken-2"}},[_vm._v(" label ")])],1):_vm._e()]}}],null,true)},[_c('v-card',{staticClass:"pa-2"},_vm._l((_vm.tags),function(tag,index){return _c('v-chip',{key:index,staticClass:"ma-1",attrs:{"small":"","label":""}},[_vm._v(" "+_vm._s(tag)+" ")])}),1)],1)],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }