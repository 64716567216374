<template>
  <v-hover v-slot:default="{ hover }">
    <v-card
      @click.stop="goToEvento(evento.id)"
      style="overflow: hidden"
      :style="hover ? `border-color: ${evento.cor} !important;` : ''"
      class="pa-3"
      outlined
    >
      <h3 style="font-size: 14px; font-weight: 500" class="mb-1">
        {{ excerpt(evento.cliente, 32).toUpperCase() }}
      </h3>
      <!-- <v-divider></v-divider> -->
      <div style="display: flex" class="align-center justify-space-between">
        <div>
          <v-icon size="30" :color="evento.cor">{{ evento.icone }}</v-icon>
        </div>

        <div style="display: flex" class="align-center justify-space-between">
          <!-- Protocolo -->
          <v-menu open-on-hover bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-sheet
                color="lightgray"
                v-bind="attrs"
                v-on="on"
                rounded
                class="mr-2 pa-1"
              >
                <v-icon size="20" color="grey darken-2">
                  horizontal_split
                </v-icon>
              </v-sheet>
            </template>
            <v-card class="pa-2">
              {{ evento.protocolo }}
            </v-card>
          </v-menu>

          <!-- Tipo do Evento -->
          <v-menu open-on-hover bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-sheet
                color="lightgray"
                v-bind="attrs"
                v-on="on"
                rounded
                class="mr-2 pa-1"
              >
                <v-icon size="20" color="grey darken-2">
                  gavel
                </v-icon>
              </v-sheet>
            </template>
            <v-card class="pa-2">
              {{ evento.eventotipo }}
            </v-card>
          </v-menu>

          <!-- Agendamento -->
          <v-menu open-on-hover bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-sheet
                color="lightgray"
                v-bind="attrs"
                v-on="on"
                rounded
                class="mr-2 pa-1"
              >
                <v-icon size="20" color="grey darken-2">
                  event
                </v-icon>
              </v-sheet>
            </template>
            <v-card class="pa-2">
              {{ evento.agendado_data | moment("DD/MM/YYYY") }}
              {{ evento.agendado_hora ? "as" : "" }}
              {{ evento.agendado_hora }}
            </v-card>
          </v-menu>

          <!-- Cidade -->
          <v-menu open-on-hover bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-sheet
                color="lightgray"
                v-bind="attrs"
                v-on="on"
                rounded
                class="mr-2 pa-1"
              >
                <v-icon size="20" color="grey darken-2">
                  room
                </v-icon>
              </v-sheet>
            </template>
            <v-card class="pa-2">
              {{ evento.cidade }}
            </v-card>
          </v-menu>

          <!-- Tags -->
          <v-menu open-on-hover bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-sheet
                color="lightgray"
                v-bind="attrs"
                v-on="on"
                rounded
                class="mr-2 pa-1"
                v-if="tags && tags.length"
              >
                <v-icon size="20" color="grey darken-2">
                  label
                </v-icon>
              </v-sheet>
            </template>
            <v-card class="pa-2">
              <v-chip
                class="ma-1"
                small
                label
                v-for="(tag, index) in tags"
                :key="index"
              >
                {{ tag }}
              </v-chip>
            </v-card>
          </v-menu>
        </div>
      </div>

      <!-- <v-expansion-panel-content color="transparent" class="pa-0">
      <v-card flat> -->

      <!-- <v-card-text v-if="tags && tags.length" class="px-0 pt-0 pb-0">
          <v-chip class="ma-1" small v-for="(tag, index) in tags" :key="index">
            {{ tag }}
          </v-chip>
        </v-card-text>
      </v-card>
    </v-expansion-panel-content> -->
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: "MiniCardEvento",
  props: {
    evento: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tags: [],
    };
  },
  computed: {},
  methods: {
    excerpt(text, value) {
      if (!text) return;
      if (text.length > value) {
        return text.substr(0, value) + "...";
      } else {
        return text;
      }
    },
    goToEvento(id) {
      this.$router.push({
        path: `/painel/${id}`,
      });
    },
  },
  created() {},
  mounted() {
    if (this.evento.tags && this.evento.tags.length) {
      this.tags = this.evento.tags.split(",");
    }
  },
};
</script>

<style lang="scss" scoped></style>
