<template>
  <v-expansion-panels class="mini-card-evento " :value="0">
    <v-expansion-panel>
      <v-expansion-panel-header
        hide-actions
        style="max-height: 48px !important; min-height: 42px !important; "
        class="caption pa-2 align-stretch"
        color="lightgray"
      >
        <div
          v-if="evento.cor"
          style="width: 8px; max-width: 8px; border-radius: 3px; margin-right: 5px;"
          :style="`background-color: ${evento.cor}`"
        ></div>
        <template v-if="evento.icone">
          <v-icon left>{{ evento.icone }}</v-icon>
        </template>
        <div class="d-flex align-center">{{ excerpt(evento.cliente) }}</div>

        <v-spacer> </v-spacer>
        <v-btn
          icon
          max-width="40px"
          color="anchors"
          @click.stop="goToEvento(evento.id)"
        >
          <v-icon>launch</v-icon>
        </v-btn>
      </v-expansion-panel-header>
      <v-expansion-panel-content color="transparent" class="pa-0">
        <v-card flat>
          <!-- Protocolo -->
          <v-card-text v-if="evento.protocolo" class="px-0 pt-0 pb-0">
            <v-chip
              class="pa-2 chip100"
              style="width: 100%"
              color="white"
              label
            >
              <div
                style="width: 100%"
                class="d-flex align-center justify-space-between"
              >
                <v-icon class="mr-2" color="accent" small
                  >horizontal_split</v-icon
                >
                {{ evento.protocolo }}
              </div>
            </v-chip>
          </v-card-text>

          <!-- Tipo do Evento -->
          <v-card-text v-if="evento.eventotipo" class="px-0 pt-0 pb-0">
            <v-chip
              class="pa-2 chip100"
              style="width: 100%"
              color="white"
              label
            >
              <div
                style="width: 100%"
                class="d-flex align-center justify-space-between"
              >
                <v-icon class="mr-2" :color="evento.cor" small>gavel</v-icon>
                {{ evento.eventotipo }}
              </div>
            </v-chip>
          </v-card-text>

          <!-- Agendamento -->
          <v-card-text
            v-if="evento.agendado_data && evento.agendado_hora"
            class="px-0 pt-0 pb-0"
          >
            <v-chip
              class="pa-2 chip100"
              style="width: 100%"
              color="white"
              label
            >
              <div
                style="width: 100%"
                class="d-flex align-center justify-space-between"
              >
                <v-icon class="mr-2" :color="evento.cor" small>event</v-icon>
                <div>
                  {{ evento.agendado_data | moment("DD/MM/YYYY") }}
                  {{ evento.agendado_hora ? "as" : "" }}
                  {{ evento.agendado_hora }}
                </div>
              </div>
            </v-chip>
          </v-card-text>

          <!-- Cidade -->
          <v-card-text v-if="evento.cidade" class="px-0 pt-0 pb-0">
            <v-chip
              class="pa-2 chip100"
              style="width: 100%"
              color="white"
              label
            >
              <div
                style="width: 100%"
                class="d-flex align-center justify-space-between"
              >
                <v-icon class="mr-2" small>room</v-icon>
                {{ evento.cidade }}
              </div>
            </v-chip>
          </v-card-text>

          <v-card-text v-if="tags && tags.length" class="px-0 pt-0 pb-0">
            <v-chip
              class="ma-1"
              small
              v-for="(tag, index) in tags"
              :key="index"
            >
              {{ tag }}
            </v-chip>
          </v-card-text>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: "MiniCardEvento",
  props: {
    evento: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tags: [],
    };
  },
  computed: {},
  methods: {
    excerpt(text) {
      if (!text) return;
      if (text.length > 18) {
        return text.substr(0, 18) + "...";
      } else {
        return text;
      }
    },
    goToEvento(id) {
      this.$router.push({
        path: `/painel/${id}`,
      });
    },
  },
  created() {},
  mounted() {
    if (this.evento.tags && this.evento.tags.length) {
      this.tags = this.evento.tags.split(",");
    }
  },
};
</script>

<style lang="scss" scoped>
.mini-card-evento {
  ::v-deep .v-expansion-panel {
    .v-expansion-panel-content__wrap {
      padding: 0px !important;
    }
  }
}

::v-deep .theme--light .v-expansion-panel:before {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.12) !important;
}

::v-deep .theme--dark .v-expansion-panel:before {
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.13) !important;
}
</style>
